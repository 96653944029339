<template>
  <div class="container">
    <van-search
      v-model="page.filterData.productName"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >本周</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >上周</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >本月</van-tag
        >
      </van-col>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3>{{ item.productName }}</h3>
                </div>
                <div>
                  <div style="float: left">
                    <h4>
                      计划生产：{{ item.nextWeekAmount }} +
                      {{ item.nextWeekAmount2 }}
                    </h4>
                  </div>
                </div>
              </div>
            </template>

            <van-cell>
              <van-col span="24">产品编号 ： {{ item.productCode }} </van-col>
              <van-col span="12">单位 ：{{ item.unit }}</van-col>
              <van-col span="12">规格：{{ item.specs }}</van-col>

              <van-col span="24"
                >时间段：{{ DateFromat(item.beginTime) }}至{{
                  DateFromat(item.endTime)
                }}</van-col
              >

              <van-col span="24">下单时间 ： {{ item.orderTime }} </van-col>
              <van-col span="24">下单部门：{{ item.departmentName }}</van-col>

              <van-col span="24">下单数量：{{ item.amount }} </van-col>
              
              <van-col span="24">已下生产计划：{{ item.purchaseAmount }} </van-col>
              
              <van-col span="24">已生产：{{ item.producingAmount }}</van-col>
            
              <van-col span="24">本周计划生产：{{ item.nextWeekAmount }}</van-col>
            
              <van-col span="24">实际生产：{{ item.actualAmount }}</van-col>
              <van-col span="24"
                >完成率：<span v-if="item.actualAmount">
                  {{
                    parseInt((item.actualAmount / item.nextWeekAmount) * 100)
                  }}%</span
                >
              </van-col>
              <van-col span="10">下周计划生产:{{ item.nextWeekAmount2 }}</van-col>
              <van-col span="24">责任人：{{ item.personInCharge }}</van-col>
              <van-col span="24">备注:{{ item.remark }}</van-col>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getProductCircularList } from "@/api/productArrival";

//const key = "zn-history";
export default {
  name: "circularList",

  data() {
    return {
      scroll: 0,
      refreshing: false,

      activeNames: "",
      title: "",

      upLoading: false,
      finished: false,
      articles: [],

      page: {
        current: 1,
         fromType:1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          productName: "",
        },
      },
    };
  },
  components: {},

  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {},

    getProductName(item) {
      return (
        (item.productCode == undefined ? "" : item.productCode) +
        "-" +
        item.productName
      );
    },
    DateFromat(data) {
      if (data == undefined) {
        return "";
      }
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };
      return new Date(data).Format("yyyy-MM-dd");
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toDetail(id) {
      //调用父组件记录滚动位置
      try {
        var parent = this.$parent || this.$root;
        for (let index = 0; index < 20; index++) {
          if (typeof parent.setScroll == "function") {
            parent.setScroll();
            break;
          }
          parent = parent.$parent;
        }
      } catch (error) {
        console.log(error);
      }

      this.$router.push({
        path: "/productarrival/circulardetail",
        query: { para: id },
      });
    },
    async onLoad() {
      console.log("onload...");
      this.upLoading = true;
      let aresult = await getProductCircularList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  min-height: 500px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
  box-sizing: border-box;
  /deep/.van-list__loading {
    height: calc(80vh);
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
