<template>
  <div class="container" ref="wrapper">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-tabs v-model="activeName" sticky>
      <van-tab title="生产计划" name="a">
        <div class="tabcontent">
          <CircularList></CircularList>
        </div>
      </van-tab>

      <van-tab title="周到货计划" name="b">
        <div class="tabcontent">
          <ArrivalList></ArrivalList>
        </div>
      </van-tab>
      <van-tab title="下单未到货计划" name="c">
        <div class="tabcontent">
          <OrderProduct></OrderProduct>
        </div>
      </van-tab>

      
    </van-tabs>
  </div>
</template>

<script>
import ArrivalList from "./components/arrivalList";
import CircularList from "./components/circularList";
import OrderProduct  from "./components/orderProduct";

//const key = "zn-history";
export default {
  name: "productArrival",
  data() {
    return {
      scroll: 0,

      activeName: "a",
      title: "产品生产到货通报",
    };
  },
  components: {
    ArrivalList,
    CircularList,
    OrderProduct,
  },
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  updated() {
    //this.showQW();
  },
  watch: {
    // $route(to, from) {
      
    // },
  },

  methods: {
    async initData() {
      let type = this.$route.query.type;
      if (type != undefined) {
        this.activeName = type;
      }
    },

    setScroll() {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      console.log(this.$refs.wrapper);
      console.log(this.$refs.wrapper.scrollTop);
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 30px;
}
.container {
  height: 100%;
  //overflow-y: auto;
  box-sizing: border-box;
}
.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.bbscontent {
  height: 100%;
}

.tabcontent {
  height: 100%;
}

.acontent {
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
