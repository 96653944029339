import request from '@/utils/request'

export function getProductCircularList(params) {
    return request({
        url: '/api/ProductArrival/CircularListByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function getProductArrivalList(params) {
    return request({
        url: '/api/ProductArrival/ArrivalListByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}