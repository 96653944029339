<template>
  <div class="container">
    <van-search
      v-model="page.filterData.productName"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >本周</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >上周</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >本月</van-tag
        >
      </van-col>
    </van-cell>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3>{{ item.productName }}</h3>
                </div>

                <!-- <div style="float: left">
                    <h4>采购时间：{{ DateFromat(item.orderTime) }}</h4>
                  
                    
                  </div> -->
                
                  <div >
                    <h4>预计到货数量：{{ item.arrivalAmmout }}</h4>
                  </div>
                  <div>
                   <h4> 
                    <span  v-if="!item.isProduce">外采</span >
                    <span v-if="item.isProduce">自产</span>
                    </h4>
                  </div>
               
              </div>
            </template>
            <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
            <van-cell>
              <van-col span="24"> 产品编号 ： {{ item.productCode }} </van-col>
<van-col span="24"> 溯源等级 ： {{ item.traceLevel }} </van-col>
              <van-col span="24" style=""> 单位：{{ item.unit }}</van-col>

              <van-col span="24"
                >时间段：{{ DateFromat(item.beginTime) }}至{{
                  DateFromat(item.endTime)
                }}</van-col
              >
              <van-col span="24">下单部门：{{ item.departmentName }}</van-col>
              <van-col span="24"
                >采购时间：{{ DateFromat(item.orderTime) }}</van-col
              >

              <van-col span="24">预计到货时间：{{ item.arrivalTime }}</van-col>
              <van-col span="24"
                >实际到货时间：{{
                  item.actualTime
                    ? item.actualTime.replace("T00:00:00", "")
                    : ""
                }}</van-col
              >
              <van-col span="24"
                >到货时间准确性：<span v-if="item.actualTime"
                  >{{ getDaysBetween(item.actualTime, item.arrivalTime) }}
                </span>
              </van-col>
              <van-col span="24"
                >预计到货数量： {{ item.arrivalAmmout }}</van-col
              >
              <van-col span="24"
                >系统实际到货数量：{{ item.actualAmount }}</van-col
              >
              <van-col span="24"
                >完成率：<span v-if="item.actualAmount">
                  {{
                    parseInt((item.actualAmount / item.arrivalAmmout) * 100)
                  }}%</span
                >
              </van-col>
              <van-col span="24">到货仓库：{{ item.warehouse }}</van-col>

              <!-- <van-col span="24">是否完结：<span v-if="item.actualAmount && item.actualAmount>=item.arrivalAmmout">已完成</span>
              <span v-if="item.actualAmount && item.actualAmount<item.arrivalAmmout">未完成</span>
              
              </van-col> -->
              <van-col span="24">差异原因：{{ item.diffReasons }}</van-col>
              <van-col span="24"
                >剩余到货时间：{{ item.nextArrivalTime }}</van-col
              >

              <van-col span="24">负责人：{{ item.personInCharge }}</van-col>
              <van-col span="24">备注：{{ item.remark }}</van-col>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getProductArrivalList } from "@/api/productArrival";

//const key = "zn-history";
export default {
  name: "arrivalList",
  props: {
    type: Number,
    productId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,

      title: "",
      activeNames: "",
      upLoading: false,
      finished: false,
      articles: [],

      page: {
        current: 1,
         fromType:1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          productName: "",
        },
      },
    };
  },
  components: {},

  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    DateFromat(data) {
      if (!data) {
        return "";
      }
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };
      try {
        return new Date(data).Format("yyyy-MM-dd");
      } catch (error) {
        return data;
      }
    },
    // 相差的天数
    getDaysBetween: function (dateString1, dateString2) {
      if (!dateString1 || !dateString2) {
        return "";
      }
      try {
        dateString1 = dateString1.replace("T00:00:00", "");
        dateString2 = dateString2.replace("T00:00:00", "");
        var startDate = Date.parse(dateString1);
        var endDate = Date.parse(dateString2);
        var days = Math.ceil((endDate - startDate) / (1 * 24 * 60 * 60 * 1000));

        if (days == 0) {
          return "准时";
        }
        if (days < 0) {
          return "晚" + -1 * days + "天";
        }
        if (days > 0) {
          return "早" + days + "天";
        }
      } catch (error) {
        console.log(error);
      }
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    // toArticleDetail(id) {
    //   //调用父组件记录滚动位置
    //   try {
    //     var parent = this.$parent || this.$root;
    //     for (let index = 0; index < 20; index++) {
    //       if (typeof parent.setScroll == "function") {
    //         parent.setScroll();
    //         break;
    //       }
    //       parent = parent.$parent;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }

    //   this.$router.push({
    //     path: "/article/detail",
    //     query: { para: id, type: this.page.filterData.typeId },
    //   });
    // },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getProductArrivalList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  min-height: 500px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-list__loading {
    height: calc(80vh);
  }
  padding-bottom: 60px;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
