<template>
  <div class="container" ref="wrapper">
    <div v-if="articles.detailsHtml!=undefined" class="acontent" v-html="articles.detailsHtml"></div>
    <div v-if="articles.detailsHtml==undefined" class="acontent" >
         <van-empty description="暂无数据" image="/image/empty-image-default.png" />
    </div>
  </div>
</template>

<script>
import { getArticleList, } from "@/api/article";

//const key = "zn-history";
export default {
  name: "SystemMessage",
  data() {
    return {
      scroll: 0,
      refreshing: false,

      title: "系统通知",

      upLoading: false,
      finished: false,
      articles: {},
      page: {
        current: 1,
        pageSize: 1,
        sorterType: 0,
        filterData: {
          typeId: 109,
        },
      },
    };
  },
  components: {},
  activated() {
  
  
  },
  updated() {
    this.addpreview();
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    async initData() {
      let aresult = await getArticleList({ ...this.page }); //({ ...this.page })
      if(aresult.data.data!=undefined && aresult.data.data.length>0){
         this.articles= aresult.data.data[0]
      }
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview",this.articles.id);
        });

       });
      this.$previewRefresh();
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 80px;
  }
}
.zntitle {
  height: 40px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  .acontent{
    padding-bottom: 35px;
    margin-bottom: 35px;
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
